
























import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import CoefficientConfig from "@/models/CoefficientConfig";
import CoefficientService from "@/services/CoefficientService";
import UnitService from "@/services/UnitService";
import { Guid } from "guid-typescript";
import OperationCoefficientException from "@/models/OperationCoefficientException";
import OperationService from "@/services/OperationService";
import OperationExceptionService from "@/services/OperationExceptionService";
import { UserRoleEnum } from "@/utils/Enums";
import Constants from "@/utils/Constants";

type Data = {
  columns: Array<Object>;
  rows: Array<Object>;
};

@Component({
  components: {
    Table: () => import("@/components/Shared/Table/Table.vue"),
  },
})
export default class OperationCoefficientExceptionSetup extends Vue {
  @Prop({ required: true }) public bopModelId!: string;

  public data: Data = {
    columns: [],
    rows: [],
  };
  public showModal: boolean = false;
  private url: string = OperationExceptionService.getOperationCoefficientExceptionsTableUrl(
    this.bopModelId,
    this.$i18n.locale
  );
  private modifiedCoefficientConfigs: Array<OperationCoefficientException> = [];

  get isNotAllowedToEdit(): Boolean {
    return !this.$store.getters.isAllowed(UserRoleEnum.centralAdministrator);
  }
  /**
   * Methods
   */
  private async remove(data: any) {
    const modalResponse = await this.$bvModal.msgBoxConfirm(
      this.$t(
        "bopModelSetup.CoefficientException.confirmDelteMessage"
      ).toString(),
      {
        title: this.$t(
          "bopModelSetup.CoefficientException.confirmDelteTitle"
        ).toString(),
        size: "lg",
        buttonSize: "lg",
        okVariant: "primary",
        cancelVariant: "secondary",
        okTitle: this.$t(
          "bopModelSetup.CoefficientException.confirmDelteYes"
        ).toString(),
        cancelTitle: this.$t(
          "bopModelSetup.CoefficientException.confirmDelteNo"
        ).toString(),
        footerClass: "p-2",
        hideHeaderClose: false,
        centered: true,
        dialogClass: "modal-app",
      }
    );

    if (modalResponse) {
      const rowData = data.rowData;
      if (!rowData.operationId.length || !rowData.coefficientId.length) {
        (<any>this.$refs.table).deleteRow(data.rowIndex);
        const indexToRemove = this.modifiedCoefficientConfigs.findIndex(
          (el) => el.id === rowData.id
        );
        this.modifiedCoefficientConfigs.splice(indexToRemove, 1);
        this.showSuccessRemoveNotification();
        return;
      }

      try {
        const response = await OperationExceptionService.deleteCoefficientOperationException(
          rowData.operationId,
          rowData.coefficientId
        );
        if (response.status === 200) {
          const indexToRemove = this.modifiedCoefficientConfigs.findIndex(
            (el) => el.id === rowData.id
          );
          this.modifiedCoefficientConfigs.splice(indexToRemove, 1);
          (<any>this.$refs.table).deleteRow(data.rowIndex);
          this.$nextTick().then(() => {
            this.$store.commit(
              "changeHasBeenModified",
              this.modifiedCoefficientConfigs.length !== 0
            );
          });
          this.showSuccessRemoveNotification();
        }
      } catch (err) {
        const errResponse = err.response;
        let text = this.$t(
          "bopModelSetup.CoefficientException.deleteError"
        ).toString();
        if (errResponse.status === 500) {
          if (errResponse.data.detail === "instanciatedInBop") {
            text = this.$t(
              "bopModelSetup.CoefficientException.deleteErrorUsedInBop"
            ).toString();
          } else if (errResponse.data.detail === "definingAnOperation") {
            text = this.$t(
              "bopModelSetup.CoefficientException.definingAnOperation"
            ).toString();
          }
        }
        this.$notify({
          group: "globalError",
          type: "error",
          title: this.$t(
            "bopModelSetup.CoefficientException.deleteErrorTitle"
          ).toString(),
          text,
          duration: Constants.DEFAULT_WAIT_ERROR_NOTIFICATIONS,
        });
      }
    }
  }
  public showSuccessRemoveNotification() {
    this.$notify({
      group: "global",
      type: "success",
      title: this.$t(
        "bopModelSetup.CoefficientException.deleteSuccessTitle"
      ).toString(),
      text: this.$t(
        "bopModelSetup.CoefficientException.deleteSuccess"
      ).toString(),
    });
  }
  public async save() {
    if (this.modifiedCoefficientConfigs.length) {
      try {
        const response = await OperationExceptionService.SaveOperationCoefficientsExceptions(
          this.modifiedCoefficientConfigs
        );

        if (response.status === 200) {
          this.$notify({
            group: "global",
            type: "success",
            title: this.$t(
              "bopModelSetup.CoefficientException.saveSuccess.title"
            ).toString(),
            text: this.$t(
              "bopModelSetup.CoefficientException.saveSuccess.text"
            ).toString(),
          });
          this.refresh();
        }
      } catch (err) {
        this.$notify({
          group: "globalError",
          type: "error",
          title: this.$t(
            "bopModelSetup.CoefficientException.saveError.text"
          ).toString(),
          text: this.$t(
            "bopModelSetup.CoefficientException.saveError.text"
          ).toString(),
          duration: Constants.DEFAULT_WAIT_ERROR_NOTIFICATIONS,
        });
      }
    }
  }
  public async addData() {
    const newOperationCoefficientException = new OperationCoefficientException();
    (<any>this.$refs.table).updatedRows.unshift(
      newOperationCoefficientException
    );
    (<any>this.$refs.table).modify(newOperationCoefficientException);
  }

  public refresh(): void {
    this.modifiedCoefficientConfigs = [];
    (<any>this.$refs.table).refreshAPI();
  }

  /**
   * Hooks
   */
  public async mounted() {
    // gets the coefficient metadata to construct the table
    const coefficients = (
      await CoefficientService.getExceptionCoefficientTypes(
        this.bopModelId,
        this.$i18n.locale
      )
    ).data;
    const operations = (
      await OperationService.getOperationsExceptions(
        this.bopModelId,
        this.$i18n.locale
      )
    ).data;

    this.data.columns = [
      {
        name: "dropdown",
        title: this.$t("bopModelSetup.exception.operation"),
        sortField: "operationName",
        property: "operationId",
        display: "operationName",
        options: operations,
        valueField: "key",
        textField: "value",
      },
      {
        name: "dropdown",
        title: this.$t("bopModelSetup.exception.coefficient"),
        sortField: "coefficientName",
        property: "coefficientId",
        display: "coefficientName",
        options: coefficients,
        valueField: "id",
        textField: "name",
      },
      {
        name: "actions",
        title: "",
        removeButton: true,
        duplicateButton: false,
        disableRemoveButton: this.isNotAllowedToEdit,
      },
    ];
  }
}
